import React, { useState, useEffect } from 'react';
import { Table, Container, Alert, Button, Modal, Form, Card, Spinner } from 'react-bootstrap';
import DonationProgressBar from './DonationProgressBar';
import apiClient from "../apiClient"; // Import the reusable Axios instance

const DonationViewAdmin = () => {
  const [donations, setDonations] = useState([]);
  const [editingField, setEditingField] = useState(null); // Track the field being edited
  const [editedValue, setEditedValue] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [loading, setLoading] = useState(false); // New loading state
  const [message, setMessage] = useState('');
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [currentDonation, setCurrentDonation] = useState(null); // Track donation for WhatsApp


  useEffect(() => {
    fetchDonations();
  }, []);


  const handleSendWhatsApp = (donation) => {
    const { id, firstname } = donation;

    const defaultMessage = `

                  *Reminder: Please Complete Your Donation Transaction*

Dear *${firstname}*,

We noticed you started a donation but haven’t completed the process. If you have completed the transaction  please upload the *screenshot* of the transaction along with the *Transaction ID* and submit them to us for tracking the donation payment by clicking on this link:

      https://www.support-jerome.org/confirm-donation?id=${id}

If you haven't completed the transaction yet, please choose a suitable payment method after clicking on the above link. 

You will see a *Blue* button on the top of the page for choosing a suitable payment method. 

Clicking on  *UPI / Phonepay / GPay(Google Pay) / Paytm*, a QR Code will be downloaded to your phone gallery, which can be scanned for the transaction.

Alternately, You can use the Phone No. *7011001050* for above payment methods.

If you prefer *Internet Banking*, the details will be available to you while choosing that method.

If you are outside India, you can use the *Pay Pal* option if you have Paypal account, otherwise *Debit / Credit Card* Option will also work.

The best option for those who are outside India, could be to select *Internet Banking* and note down the details then use any third party money transfer options like *Wise, Remitly, Xoom*, etc.

If you are not in a position to donate now, please  *cancel/delete* the details using the above link and you will see a *Red Button* on the bottom of the page for the same.

Please reply to this message, if you face any other difficulty or need any further assistance.

Thanking you again you for your generosity!    
*Support Jerome Team*`;

    setMessage(defaultMessage);
    setCurrentDonation(donation);
    setShowWhatsAppModal(true); // Show modal
  };



  const sendWhatsAppMessage = async () => {
    if (!currentDonation) return;

    const { id, phone, whatsappReminderNumber = 0 } = currentDonation;
    if (!phone) {
      alert('Phone number is missing.');
      return;
    }

    const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');

    // Update whatsappReminderNumber
    try {
      const updatedDonation = {
        ...currentDonation,
        whatsappReminderNumber: whatsappReminderNumber + 1,
      };
      await apiClient.put(`/api/donations/${id}/single-field`, updatedDonation);
      setDonations((prevDonations) =>
        prevDonations.map((donation) =>
          donation.id === id ? updatedDonation : donation
        )
      );
      alert('WhatsApp message sent and reminder count updated.');
    } catch (error) {
      console.error('Error updating WhatsApp reminder count:', error);
      alert('Failed to update WhatsApp reminder count. Please try again.');
    }

    setShowWhatsAppModal(false); // Close modal
  };


  // const handleSendWhatsAppOld = async (donation) => {
  //   const { id, phone, whatsappReminderNumber = 0, firstname } = donation;

  //   if (!phone) {
  //     alert('Phone number is missing.');
  //     return;
  //   }

  //   const message = `

  //     *Reminder: Please Complete Your Donation Transaction*

  //   Dear *${firstname}*,

  //   We noticed you started a donation but haven’t completed the process. If you have completed the transaction  please upload the *screenshot* of the transaction along with the *Transaction ID* and submit them to us for tracking the donation payment by clicking on this link:

  //     https://www.support-jerome.org/confirm-donation?id=${id}

  //   If you haven't completed the transaction yet, please choose a suitable payment method after clicking on the above link. 

  //   You will see a *Blue* button on the top of the page for choosing a suitable payment method. 

  //   Clicking on  *UPI / Phonepay / GPay(Google Pay) / Paytm*, a QR Code will be downloaded to your phone gallery, which can be scanned for the transaction.

  //   Alternately, You can use the Phone No. *7011001050* for above payment methods.

  //   If you prefer *Internet Banking*, the details will be available to you while choosing that method.

  //   If you are not in a position to donate now, please  *cancel/delete* the details using the above link and you will see a *Red Button* on the bottom of the page for the same.

  //   Thanking you again you for your generosity!    
  //   *Support Jerome Team*`;


  //   //     const message = `
  //   //  We are sorry! 
  //   //  There was an error in the link given above. Please find the updated link below;
  //   //   https://www.support-jerome.org/confirm-donation?id=${id}
  //   //   *Support Jerome Team*
  //   // `;


  //   const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  //   window.open(whatsappUrl, '_blank');

  //   setLoading(true); // Show spinner

  //   try {
  //     // Find the original object
  //     const originalDonation = donations.find((donation) => donation.id === id);

  //     if (!originalDonation) {
  //       setLoading(false);
  //       alert('Donation not found. Please refresh and try again.');
  //       return;
  //     }

  //     // Create updated object by incrementing whatsappReminderNumber
  //     const updatedDonation = {
  //       ...originalDonation,
  //       whatsappReminderNumber: whatsappReminderNumber + 1,
  //     };

  //     // Make the PUT request with the full object
  //     const response = await apiClient.put(`/api/donations/${id}/single-field`, updatedDonation);

  //     if (response.status >= 200 && response.status < 300) {
  //       // Update local state
  //       setDonations((prevDonations) =>
  //         prevDonations.map((donation) =>
  //           donation.id === id ? updatedDonation : donation
  //         )
  //       );

  //       alert('WhatsApp message sent and reminder count updated.');
  //     } else {
  //       console.error('Unexpected response status:', response.status);
  //       alert('Failed to update WhatsApp reminder count. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Error updating WhatsApp reminder count:', error);
  //     alert('Failed to update WhatsApp reminder count. Please try again.');
  //   } finally {
  //     setLoading(false); // Hide spinner
  //   }
  // };


  const handleSendReminder = async (donationId) => {
    if (!window.confirm('Are you sure you want to send a reminder email for this donation?')) return;

    setLoading(true);
    try {
      const response = await apiClient.post(
        `/api/donations/send-reminder/${donationId}`
      );
      if (response.status === 200) {
        alert('Reminder email sent successfully.');
      }
    } catch (error) {
      console.error('Error sending reminder email:', error);
      alert('Failed to send the reminder email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchDonations = async () => {
    setLoading(true); // Show spinner
    try {
      const response = await apiClient.get(`/api/donations`);
      const sortedDonations = response.data.sort((a, b) => {
        // 1. Prioritize records with isChecked === true
        if (a.isChecked === true && (b.isChecked === false || b.isChecked === undefined)) return -1;
        if (b.isChecked === true && (a.isChecked === false || a.isChecked === undefined)) return 1;

        // 1. Show donations with confirmationNumber !== null and isVerified === false on top
        if (a.confirmationNumber !== null && !a.isVerified && (b.confirmationNumber === null || b.isVerified)) return -1;
        if (b.confirmationNumber !== null && !b.isVerified && (a.confirmationNumber === null || a.isVerified)) return 1;

        // 2. Place donations with confirmationNumber === null next
        if (a.confirmationNumber === null && b.confirmationNumber !== null) return -1;
        if (a.confirmationNumber !== null && b.confirmationNumber === null) return 1;

        // 3. For remaining donations, sort by date descending
        if (a.date === null) return 1; // Place null dates at the bottom
        if (b.date === null) return -1;
        return new Date(b.date) - new Date(a.date);
      });
      setDonations(sortedDonations);
    } catch (error) {
      console.error('Error fetching donations:', error);
    } finally {
      setLoading(false); // Hide spinner
    }
  };



  const handleSave = async (id) => {
    if (!editingField || !editingField.field || editedValue === '') {
      alert('Invalid edit data. Please check and try again.');
      return;
    }

    setLoading(true); // Show spinner

    try {
      // Find the original object
      const originalDonation = donations.find((donation) => donation.id === id);

      if (!originalDonation) {
        setLoading(false);
        alert('Donation not found. Please refresh and try again.');
        return;
      }

      // Create updated object by merging the original with the new value
      const updatedDonation = {
        ...originalDonation,
        [editingField.field]: editedValue,
      };

      // Make the PUT request with the full object
      const response = await apiClient.put(
        `/api/donations/${id}/single-field`,
        updatedDonation
      );

      if (response.status >= 200 && response.status < 300) {
        // Update local state
        setDonations((prevDonations) =>
          prevDonations.map((donation) =>
            donation.id === id ? updatedDonation : donation
          )
        );

        setEditingField(null); // Reset editing state
        setEditedValue(''); // Clear edited value

        // Ensure spinner closes before showing the alert
        setTimeout(() => {
          setLoading(false); // Close spinner
          alert('Field updated successfully.');
        }, 100);
      } else {
        console.error('Unexpected response status:', response.status);
        setLoading(false); // Close spinner
        alert('Failed to update the field. Please try again.');
      }
    } catch (error) {
      console.error('Error updating donation:', error);

      setLoading(false); // Close spinner
      if (error.response) {
        alert(
          `Failed to update the donation. Server responded with status ${error.response.status}: ${error.response.data}`
        );
      } else {
        alert('Failed to update the donation. Please check your connection.');
      }
    }
  };


  // const handleDelete = async (id) => {
  //   if (window.confirm('Are you sure you want to delete this donation?')) {
  //     setLoading(true); // Show spinner
  //     try {
  //       setDonations((prevDonations) => prevDonations.filter((donation) => donation.id !== id));
  //       await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/donations/${id}`);
  //       alert('Donation deleted successfully.');
  //     } catch (error) {
  //       console.error('Error deleting donation:', error);
  //       alert('Failed to delete the donation.');
  //     } finally {
  //       setLoading(false); // Hide spinner
  //     }
  //   }
  // };

  const renderSpinner = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );


  const handleEdit = (id, field, value) => {
    if (!field || typeof value === 'undefined') {
      alert('Invalid field or value for editing.');
      return;
    }

    setEditingField({ id, field }); // Set the field being edited
    setEditedValue(field === 'isHiddenMessage' ? value.toString() : value); // Convert boolean to string for dropdown
  };



  const calculateTotalInINR = () => {
    if (!donations || donations.length === 0) {
      console.warn('No donations available.');
      return 0;
    }

    const total = donations
      .filter((donation) => donation.confirmationNumber && donation.isVerified) // Filter by confirmationNumber and isVerified
      .reduce((sum, donation) => {
        return sum + (donation.rupees || 0); // Sum up rupees, defaulting to 0 if rupees is null/undefined
      }, 0);

    return Math.round(total); // Return rounded total
  };


  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  const renderField = (id, field, value) => {
    const isEditing = editingField?.id === id && editingField?.field === field;

    if (isEditing) {
      if (field === 'isHiddenMessage' || field === 'isVerified') {
        // Boolean field logic remains unchanged
        return (
          <Form.Select
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value === 'true')} // Convert to boolean
          >
            <option value="true">True</option>
            <option value="false">False</option>
          </Form.Select>
        );
      }

      // Special case for numeric field like whatsappReminderNumber
      if (field === 'whatsappReminderNumber') {
        return (
          <Form.Control
            type="number"
            value={editedValue}
            onChange={(e) => setEditedValue(Number(e.target.value))} // Ensure numeric input
          />
        );
      }

      // Default case for string or other fields
      const isTextAreaField = field === 'message' || field === 'address';
      return (
        <Form.Control
          as={isTextAreaField ? 'textarea' : 'input'}
          rows={isTextAreaField ? 3 : undefined}
          value={editedValue}
          onChange={(e) => setEditedValue(e.target.value)}
        />
      );
    }

    return <span>{value !== undefined ? value : 'N/A'}</span>;
  };



  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this donation?')) {
      setLoading(true); // Show spinner
      try {
        setDonations((prevDonations) => prevDonations.filter((donation) => donation.id !== id));
        await apiClient.delete(`/api/donations/${id}`);
        alert('Donation deleted successfully.');
      } catch (error) {
        console.error('Error deleting donation:', error);
        alert('Failed to delete the donation.');
      } finally {
        setLoading(false); // Hide spinner
      }
    }
  };

  const renderActions = (id, field) => {
    if (editingField?.id === id && editingField?.field === field) {
      return (
        <Button variant="success" size="sm" onClick={() => handleSave(id)}>
          Save
        </Button>
      );
    }
    return (
      <Button
        variant="primary"
        size="sm"
        onClick={() => handleEdit(id, field, donations.find((d) => d.id === id)[field])}
      >
        Edit
      </Button>
    );
  };

  return (
    <Container className="py-5">

      <h2 className="text-center mb-4">Donation Details</h2>

      {loading ? (
        renderSpinner()
      ) : donations.length === 0 ? (
        <Alert variant="info" className="text-center">
          No donation data available.
        </Alert>
      ) : (
        <>
          {/* Desktop View */}
          <DonationProgressBar />

          <br></br>
          <Table striped bordered hover responsive className="d-none d-lg-table">
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Rupees</th>
                <th>US Dollar</th>
                <th>Message</th>
                <th>Hidden Message</th>
                <th>Country</th>
                <th>whatsappReminderNumber</th>
                <th>isChecked</th> {/* New Field */}
                <th>receivedINR</th> {/* New Field */}
                <th>Notes</th> {/* New Field */}
                <th>Transaction Status</th>
                <th>Transaction ID</th>
                <th>isVerified</th>
                <th>Payment Method</th>
                <th>Screenshot</th>
                <th>Actions</th>
              </tr>
            </thead>

            <tbody>
              {donations.map((donation, index) => (
                <tr key={donation.id}>
                  <td>{index + 1}</td>
                  <td>{donation.id}</td>
                  <td>{renderField(donation.id, 'firstname', donation.firstname)} {renderActions(donation.id, 'firstname')}</td>
                  <td>{renderField(donation.id, 'lastname', donation.lastname)} {renderActions(donation.id, 'lastname')}</td>
                  <td>{renderField(donation.id, 'email', donation.email)} {renderActions(donation.id, 'email')}</td>
                  <td>{renderField(donation.id, 'phone', donation.phone)} {renderActions(donation.id, 'phone')}</td>
                  <td>{renderField(donation.id, 'address', donation.address)} {renderActions(donation.id, 'address')}</td>
                  <td>{donation.currency}</td>
                  <td>{renderField(donation.id, 'amount', donation.amount)} {renderActions(donation.id, 'amount')}</td>
                  <td>{renderField(donation.id, 'rupees', donation.rupees)} {renderActions(donation.id, 'rupees')}</td>
                  <td>{renderField(donation.id, 'usDollar', donation.usDollar)} {renderActions(donation.id, 'usDollar')}</td>
                  <td>{renderField(donation.id, 'message', donation.message)} {renderActions(donation.id, 'message')}</td>
                  <td>{renderField(donation.id, 'isHiddenMessage', donation.isHiddenMessage)} {renderActions(donation.id, 'isHiddenMessage')}</td>
                  <td>{renderField(donation.id, 'country', donation.country)} {renderActions(donation.id, 'country')}</td>
                  <td>{renderField(donation.id, 'whatsappReminderNumber', donation.whatsappReminderNumber)} {renderActions(donation.id, 'whatsappReminderNumber')}</td>

                  {/* New Fields */}
                  <td>{renderField(donation.id, 'isChecked', donation.isChecked)} {renderActions(donation.id, 'isChecked')}</td>
                  <td>{renderField(donation.id, 'receivedINR', donation.receivedINR)} {renderActions(donation.id, 'receivedINR')}</td>
                  <td>{renderField(donation.id, 'notes', donation.notes)} {renderActions(donation.id, 'notes')}</td>

                  <td>{donation.confirmationNumber}</td>
                  <td>{renderField(donation.id, 'isVerified', donation.isVerified)} {renderActions(donation.id, 'isVerified')}</td>
                  <td>{donation.paymentMethod}</td>
                </tr>
              ))}
            </tbody>

          </Table>

          {/* Mobile View */}
          <div className="d-lg-none">
            <strong>Total Amount (INR): {calculateTotalInINR().toFixed(2)}</strong>
            {donations.map((donation, index) => (
              <Card key={donation.id} className="mb-3">
                <Card.Body>
                  <Card.Title>
                    {index + 1}. {renderField(donation.id, 'firstname', donation.firstname)} {renderActions(donation.id, 'firstname')} {renderField(donation.id, 'lastname', donation.lastname)} {renderActions(donation.id, 'lastname')}
                    <br />
                    <p>{donation.id}</p>
                    <button
                      onClick={() => {
                        navigator.clipboard
                          .writeText(donation.id)
                          .then(() => alert("Text copied successfully!"))
                          .catch(() => alert("Failed to copy text."));
                      }}
                      style={{ padding: "5px 10px", cursor: "pointer", fontSize: "0.9rem", border: "1px solid #ccc", borderRadius: "5px" }}
                    >
                      Copy
                    </button>
                  </Card.Title>
                  <Card.Text>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Email:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'email', donation.email)}
                      </div>
                      {renderActions(donation.id, 'email')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Phone:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'phone', donation.phone)}
                      </div>
                      {renderActions(donation.id, 'phone')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Address:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'address', donation.address)}
                      </div>
                      {renderActions(donation.id, 'address')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Currency:</strong>
                      <span style={{ marginLeft: '10px' }}>{donation.currency || 'N/A'}</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Amount:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'amount', donation.amount)}
                      </div>
                      {renderActions(donation.id, 'amount')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Rupees:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'rupees', donation.rupees)}
                      </div>
                      {renderActions(donation.id, 'rupees')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>US Dollar:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'usDollar', donation.usDollar)}
                      </div>
                      {renderActions(donation.id, 'usDollar')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Message:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'message', donation.message)}
                      </div>
                      {renderActions(donation.id, 'message')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Hidden Message:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'isHiddenMessage', donation.isHiddenMessage)}
                      </div>
                      {renderActions(donation.id, 'isHiddenMessage')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Country:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'country', donation.country)}
                      </div>
                      {renderActions(donation.id, 'country')}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>isChecked:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'isChecked', donation.isChecked ? 'true' : 'false')}
                      </div>
                      {renderActions(donation.id, 'isChecked')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>receivedINR:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'receivedINR', donation.receivedINR)}
                      </div>
                      {renderActions(donation.id, 'receivedINR')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>Notes:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'notes', donation.notes)}
                      </div>
                      {renderActions(donation.id, 'notes')}
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                      <strong>whatsappReminderNumber:</strong>
                      <span style={{ marginLeft: '10px' }}>
                        {renderField(donation.id, 'whatsappReminderNumber', donation.whatsappReminderNumber)}
                      </span>
                      {renderActions(donation.id, 'whatsappReminderNumber')}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                      <strong>Transaction Status:</strong>{' '}
                      {!donation.confirmationNumber ? (
                        <>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => handleSendReminder(donation.id)}
                            disabled={loading}
                            style={{ marginRight: '10px' }} // Added margin
                          >
                            Send Reminder Email
                          </Button>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => handleSendWhatsApp(donation)}
                          >
                            Send Reminder WhatsApp
                          </Button>
                        </>
                      ) : (
                        <span style={{ marginLeft: '10px' }}>Transaction Completed</span>
                      )}
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                      <strong>Transaction ID:</strong> <span style={{ marginLeft: '10px' }}>{donation.confirmationNumber || 'N/A'}</span>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                      <strong>isVerified:</strong>
                      <div style={{ marginLeft: '10px', flexGrow: 1 }}>
                        {renderField(donation.id, 'isVerified', donation.isVerified ? 'true' : 'false')}
                      </div>
                      {renderActions(donation.id, 'isVerified')}
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                      <strong>Payment Method:</strong> <span style={{ marginLeft: '10px' }}>{donation.paymentMethod || 'N/A'}</span>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                      <strong>Screenshot:</strong>
                      <br />
                      {donation.screenshotUrl ? (
                        <img
                          src={donation.screenshotUrl}
                          alt="Screenshot"
                          style={{
                            width: '100%',
                            height: 'auto',
                            cursor: 'pointer',
                            marginTop: '10px',
                            borderRadius: '5px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                          }}
                          onClick={() => handleImageClick(donation.screenshotUrl)}
                        />
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </Card.Text>



                  <Button variant="danger" size="sm" onClick={() => handleDelete(donation.id)}>
                    Delete
                  </Button>
                </Card.Body>
              </Card>
            ))}
            {/* <Card className="mt-3">
              <Card.Body>
                <strong>Total Amount (INR): {calculateTotalInINR().toFixed(2)}</strong>
              </Card.Body>
            </Card> */}
          </div>

          {/* Modal */}
          <Modal show={showModal} onHide={closeModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Screenshot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalImage ? (
                <img src={modalImage} alt="Enlarged Screenshot" style={{ width: '100%' }} />
              ) : (
                <p>No screenshot available.</p>
              )}
            </Modal.Body>
          </Modal>
        </>
      )}

      <Modal show={showWhatsAppModal} onHide={() => setShowWhatsAppModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit WhatsApp Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={8}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowWhatsAppModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={sendWhatsAppMessage}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );

};

export default DonationViewAdmin;

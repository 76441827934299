import React, { useState, useEffect } from 'react';
import { Table, Container, Button, Form, Spinner, Alert, Row, Col, Card } from 'react-bootstrap';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { storage } from "../firebaseConfig";
import apiClient from "../apiClient";

const DonationView = () => {
  const [donations, setDonations] = useState([]);
  const [editing, setEditing] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDonations();
  }, []);

  const fetchDonations = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get('/api/donations');
      const filteredDonations = response.data
        .filter(donation => donation.isVerified === false && (!donation.isChecked || donation.isChecked === false) && donation.confirmationNumber === null)
        .sort((a, b) => {
          if (!a.date) return 1;
          if (!b.date) return -1;
          return new Date(b.date) - new Date(a.date);
        });
      setDonations(filteredDonations);
    } catch (error) {
      console.error('Error fetching donations:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalRupees = () => {
    return donations.reduce((acc, donation) => acc + (parseFloat(donation.rupees) || 0), 0);
  };

  const handleEdit = (id, field) => {
    setEditing({ id, field });
    const donation = donations.find(d => d.id === id);
    setEditedValues({ [field]: donation[field] !== undefined ? donation[field] : '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedValues(prev => ({ ...prev, [name]: name === 'isChecked' ? (value === 'true') : value }));
  };

  const handleFileChange = async (e, donation) => {
    const file = e.target.files[0];
    if (file) {
      const downloadURL = await uploadScreenshotToStorage(file);
      const updatedDonation = { ...donation, screenshotUrl: downloadURL };
      await handleSave(updatedDonation.id, updatedDonation);
    }
  };

  const uploadScreenshotToStorage = async (file) => {
    const storageRef = ref(storage, `screenshots/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const handleDeleteScreenshot = async (donation) => {
    if (donation.screenshotUrl) {
      try {
        const filePath = donation.screenshotUrl.split('/o/')[1].split('?')[0];
        const storageRef = ref(storage, decodeURIComponent(filePath));
        await deleteObject(storageRef);
        const updatedDonation = { ...donation, screenshotUrl: '' };
        await handleSave(updatedDonation.id, updatedDonation);
      } catch (error) {
        console.error('Error deleting screenshot:', error);
      }
    }
  };

  const handleSave = async (id, updatedData = null) => {
    try {
      const originalDonation = donations.find(d => d.id === id);
      const updatedDonation = updatedData || { ...originalDonation, ...editedValues };

      await apiClient.put(`/api/donations/${id}/single-field`, updatedDonation);
      await fetchDonations();
      setEditing(null);
    } catch (error) {
      console.error('Error saving donation:', error);
    }
  };

  const renderField = (donation, field) => {
    const isEditing = editing?.id === donation.id && editing?.field === field;

    return isEditing ? (
      <>
        {field === 'screenshotUrl' ? (
          <Form.Control type="file" onChange={(e) => handleFileChange(e, donation)} />
        ) : field === 'isChecked' ? (
          <Form.Select name={field} value={editedValues[field] ? 'true' : 'false'} onChange={handleChange}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </Form.Select>
        ) : field === 'notes' ? (
          <Form.Control as="textarea" name={field} value={editedValues[field]} onChange={handleChange} />
        ) : (
          <Form.Control name={field} value={editedValues[field]} onChange={handleChange} />
        )}
        <Button variant="success" onClick={() => handleSave(donation.id)}>Save</Button>
      </>
    ) : (
      <>
        {field === 'screenshotUrl' && donation[field] ? (
          <>
            <img src={donation[field]} alt="Screenshot" style={{ maxWidth: '100px', display: 'block' }} />
            <Button variant="danger" size="sm" onClick={() => handleDeleteScreenshot(donation)}>Delete</Button>
          </>
        ) : (
          <span>{field === 'isChecked' ? (donation[field] ? 'Yes' : 'No') : (donation[field] || 'N/A')}</span>
        )}
        <Button variant="primary" onClick={() => handleEdit(donation.id, field)} className="ms-2">Edit</Button>
      </>
    );
  };

  if (loading) return <Spinner animation="border" />;

  return (
    <Container>
      <h2>Edit Donations</h2>
      <h4>Total Rupees: {calculateTotalRupees()}</h4>
      {donations.length === 0 ? (
        <Alert variant="info">No donations to display.</Alert>
      ) : (
        <>
          <div className="d-none d-md-block">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Currency</th>
                  <th>Amount</th>
                  <th>Rupees</th>
                  <th>US Dollar</th>
                  <th>Message</th>
                  <th>Country</th>
                  <th>Is Hidden Message</th>
                  <th>Transaction ID</th>
                  <th>Received INR</th>
                  <th>Screenshot</th>
                  <th>Is Checked</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {donations.map((donation, index) => (
                  <tr key={donation.id}>
                    <td>{index + 1}</td>
                    <td>{donation.id}</td>
                    <td>{donation.firstname}</td>
                    <td>{donation.lastname}</td>
                    <td>{donation.email}</td>
                    <td>{donation.phone}</td>
                    <td>{donation.address}</td>
                    <td>{donation.currency}</td>
                    <td>{donation.amount}</td>
                    <td>{donation.rupees}</td>
                    <td>{donation.usDollar}</td>
                    <td>{donation.message}</td>
                    <td>{donation.country}</td>
                    <td>{donation.isHiddenMessage ? 'Yes' : 'No'}</td>
                    <td>{renderField(donation, 'confirmationNumber')}</td>
                    <td>{renderField(donation, 'receivedINR')}</td>
                    <td>{renderField(donation, 'screenshotUrl')}</td>
                    <td>{renderField(donation, 'isChecked')}</td>
                    <td>{renderField(donation, 'notes')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="d-md-none">
            <Row>
              {donations.map((donation, index) => (
                <Col xs={12} key={donation.id} className="mb-3">
                  <Card>
                    <Card.Body>
                      <Card.Title>{index + 1}. {donation.firstname} {donation.lastname}</Card.Title>
                      <Card.Text>ID: {donation.id}</Card.Text>
                      <Card.Text>Email: {donation.email}</Card.Text>
                      <Card.Text>Phone: {donation.phone}</Card.Text>
                      <Card.Text>Address: {donation.address}</Card.Text>
                      <Card.Text>Currency: {donation.currency}</Card.Text>
                      <Card.Text>Amount: {donation.amount}</Card.Text>
                      <Card.Text>Rupees: {donation.rupees}</Card.Text>
                      <Card.Text>US Dollar: {donation.usDollar}</Card.Text>
                      <Card.Text>Message: {donation.message}</Card.Text>
                      <Card.Text>Country: {donation.country}</Card.Text>
                      <Card.Text>Is Hidden Message: {donation.isHiddenMessage ? 'Yes' : 'No'}</Card.Text>
                      <Card.Text>Transaction ID: {renderField(donation, 'confirmationNumber')}</Card.Text>
                      <Card.Text>Received INR: {renderField(donation, 'receivedINR')}</Card.Text>
                      <Card.Text>Screenshot: {renderField(donation, 'screenshotUrl')}</Card.Text>
                      <Card.Text>Is Checked: {renderField(donation, 'isChecked')}</Card.Text>
                      <Card.Text>Notes: {renderField(donation, 'notes')}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </Container>
  );
};

export default DonationView;
